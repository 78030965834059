.page_container{
  background-color:#ffffff;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
}

.container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo{
  margin-top: 60px;
  margin-right: 0px;
  background-image: url('../../../public/fullLogo.png');
  background-repeat: no-repeat;
  background-size:cover;
  width: 340px;
  height: 100px;
}

.container_div {
  margin-top:40px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.blueBack{
  background-image: url('../../../public/blueBack.png');
  background-repeat: no-repeat;
  background-size:cover;
  width: 100%;
  height: 35vh;
  position: absolute;
  bottom: 0;
}

/* buttons - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.btn {
  position: absolute;
  bottom: 18vh;
  z-index: 1;
  margin-top:40px;
  padding: 17px;
  font-size: 1.95rem;
  font-weight: 600;
  text-align: center;
  border-radius: 40px;
  border: none;
  color: rgba(66, 64, 64, 0.889);
  color: #648ef8;
  transition: 0.3s;
  cursor: pointer;
  width: 260px;
  background-color: #ffffff;
  font-family: 'Rubik', sans-serif;
}
.btn:hover {
  color: #254aa9;
}

.register_div{
  position: absolute;
  z-index: 3;
  bottom:75px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  direction: rtl;
  gap: 8px;
}

.register_btn{
  border: none;
  color: rgb(255, 255, 255);
  font-family: 'Rubik', sans-serif;
  background-color: #254aa900;
  font-size: 20px;
  font-weight: 500;
  padding: 0px;
  border-bottom: 2px solid white;
  cursor: pointer;
}

.register_label{
  border: none;
  color: rgb(255, 255, 255);
  font-family: 'Rubik', sans-serif;
  background-color: #254aa900;
  font-size: 18px;
  font-weight: 500;
}

/* inputs - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.password_div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.show_password{
  color: rgba(86, 86, 86, 0.663);
  cursor: pointer;
  font-size: 30px;
  position: absolute;
  top: 15px;
  left: 10px;
}

.input_email{
  font-size: 26px;
  margin-bottom: 40px;
  padding: 13px;
  width: 300px;
  border-radius: 25px;
  border: none;
  font-family: 'Rubik', sans-serif;
  color: rgb(70, 70, 70);
  background-color: #F3F3F3;
  text-align: left;
}
.input_email::placeholder{
  color: #2e2e2e7c;
  text-align: right;
}
.input_email:focus{
  outline: none;
}

.input{
  direction: rtl;
  font-size: 26px;
  margin-bottom: 40px;
  padding: 13px;
  width: 300px;
  border-radius: 25px;
  border: none;
  font-family: 'Rubik', sans-serif;
  color: rgb(70, 70, 70);
  background-color: #F3F3F3;
}
.input::placeholder{
  color: #2e2e2e7c;
  direction: ltr;
}
.input:focus{
  outline: none;
}

@keyframes vibration {
  0% { transform: translateX(0); }
  25% { transform: translateX(-2px) rotateZ(-2deg); }
  50% { transform: translateX(2px) rotateZ(2deg); }
  75% { transform: translateX(-2px) rotateZ(-2deg); }
  100% { transform: translateX(0); }
}
.validation_p{
  position: absolute;
  font-size: 1.4em;
  font-family: 'Rubik', sans-serif;
  color: rgba(242, 65, 65, 0.793);
  margin-top: 200px;
  /* animation: vibration 0.3s; */
  direction: ltr;
  width: fit-content;
}

@keyframes slideUpBtn {
  0% { bottom: -230px; } /* Start position outside of the view */
  100% { bottom: 0px; } /* Final position at margin-top value */
}
@keyframes slideUpRegisterBtn {
  0% { bottom: -200px; } /* Start position outside of the view */
  100% { bottom: 70px; } /* Final position at margin-top value */
}
@keyframes slideUp {
  0% { top: 600px; } /* Start position outside of the view */
  100% { top: 400px;} /* Final position at margin-top value */
}

@media only screen and (min-width: 480px) {
  .page_container{
    background-color:#ffffff;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .container{
    background-color:#ffffff;
    margin-top: 70px;
    overflow: hidden;
    position: relative;
    width:420px;
    border-radius: 30px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  }
  .blueBack{
    background-image: url('../../../public/blueBack.png');
    background-repeat: no-repeat;
    background-size:contain;
    position: absolute;
    top: 400px;
    width: 100%;
    height: 105vh;
    animation: slideUp 1s forwards;
  }
  .btn{
    position: relative;
    margin-bottom:105px;
    margin-top: 50px;
    animation: slideUpBtn 1s forwards;
  }
  .register_div{
    animation: slideUpRegisterBtn 1s forwards;
  }
  .validation_p{
    margin-top: -40px;
  }
  .input{
    margin-top: -10px;
  }
  .show_password{
    top: 5px;
    left: 10px;
  }
}
@media only screen and (min-height: 815px) and (max-width: 470px) {
  .btn{
    bottom: 20vh;
  }
  .container_div {
    margin-top:100px;
  }
  .register_div{
    bottom: 130px;
  }
}
@media only screen and (max-width: 365px) {
  .input{
    width: 260px;
  }
  .input_email{
    width: 260px;
  }
  .logo{
    margin-top: 60px;
    margin-right: 0px;
    background-image: url('../../../public/fullLogo.png');
    background-repeat: no-repeat;
    background-size:cover;
    width: 310px;
    height: 100px;
  }
}
@media only screen and (min-height: 0px) and (max-height: 750px) and (max-width: 480px){
  .logo{
    margin-top: 20px;
  }
  .container_div {
    margin-top:30px;
  }
}
@media only screen and (min-height: 911px){
  .register_div{
    bottom: 150px;
  }
}
@media only screen and (min-height: 871px) and (max-height: 910.9px){
  .register_div{
    bottom: 140px;
  }
}
@media only screen and (min-height: 815px) and (max-height: 870.9px){
  .register_div{
    bottom: 130px;
  }
}
@media only screen and (min-height: 720px) and (max-height: 814.9px){
  .register_div{
    bottom: 100px;
  }
}
@media only screen and (min-height: 691px) and (max-height: 719.9px){
  .register_div{
    bottom: 90px;
  }
}
@media only screen and (min-height: 630px) and (max-height: 690.9px){
  .register_div{
    bottom: 80px;
  }
}
  