.first_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 290px;
    padding: 12px;
    margin: 20px;
    margin-top: 85px;
    margin-bottom: 80px;
    background-color: #dde0ef73;
    border-radius: 20px;
    animation: slideInAndFade 0.5s ease-in-out;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

@keyframes slideInAndFade {
    0% {
        transform: translateX(0);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    backdrop-filter: blur(4px);
}

.user_container{
    width: 280px;
    border: none;
    border-radius: 10px;
    margin: 10px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(255, 255, 255); 
}
.editWorker{
    margin-top: 5px;
    width: 240px;
    font-size: 1.2em;
    border: none;
    border-radius: 13px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.input_edit{
    font-size: 19px;
    padding: 8px;
    border-radius: 10px;
    border: 2px solid rgba(169, 169, 169, 0.25);
    background-color: white;
    width: 260px;
    text-align: right;
    direction: rtl;
    appearance: none;
    -webkit-appearance:none;
    font-family: 'Rubik', sans-serif;
    margin-bottom: 12px;
}
.select{
    font-size: 19px;
    padding: 8px;
    border-radius: 10px;
    border: 2px solid rgba(169, 169, 169, 0.25);
    background-color: white;
    width: 280px;
    text-align: right;
    direction: rtl;
    appearance: none;
    -webkit-appearance:none;
    font-family: 'Rubik', sans-serif;
    margin-bottom: 12px;
}
.btn_div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: -15px;
    direction: rtl;
}

.edit_worker_container{
    z-index: 10;
    background-color: rgb(222, 225, 227);
    position: absolute;
    top: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 20px;
}
.edit_worker_btn{
    margin-left: 15px;
    margin-top: -10px;
    margin-bottom: 15px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 1.275rem;
    line-height: 1.25rem;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border: none;
    gap: 0.75rem;
    cursor: pointer;
    transition: all .6s ease;
    width: 130px;
    justify-content: center;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    background-color: #4f97d7a6;
    color:#ffffff;
    width: 130px;
    border-radius: 50px;
}

.edit_worker_btn_cancel{
    width: 130px;
    margin-top: -10px;
    margin-bottom: 15px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 1.275rem;
    line-height: 1.25rem;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 12px;
    border: none;
    gap: 0.75rem;
    cursor: pointer;
    transition: all .6s ease;
    justify-content: center;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    background-color: #ffffff;
    color:#4f97d7f5;
    border: 1px solid #4f97d7f5;
    border-radius: 50px;
}
.noWorkers_div{
    font-size: 20px;
    font-weight: 500;
    color: rgb(112, 112, 112);
}

.icon{
    margin-left: 10px;
    color: gray;
    font-size: 23px;
}

.delete_edit_div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}

.edit_div_options{
    border-radius: 7px;
    width: 165px;
    height: auto;
    border: none;
    position: absolute;
    direction: ltr;
    left:-26px;
    top: 35px;
    z-index: 100;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(67, 71, 85, 0.27) 1px 1px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.15em 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    animation: slideInAndFade 0.2s ease-in-out;
}
.edit_div_options::before{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: 100%;
    left: 2.2em;
    border: .75rem solid transparent;
    border-top: none;
    border-bottom-color: rgb(255, 255, 255);
    filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
    cursor: pointer;
}
.edit_div_flex{
    margin: 8px;
    direction: ltr;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
}

.icon_edit_select{
    color: rgb(95, 95, 95);
    font-size: 22px;
    cursor: pointer;
    margin-left: 10px;
}

.p{
    font-size: 18px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    direction: rtl;
    gap: 4px;
}
.role{
    color: rgb(125, 125, 125);
}

@keyframes downToUp {
    0% {
        transform: translateY(130px);
        opacity: 1;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
  }
}

.addUser_btn {
    width: 60px;
    height: 60px;
    z-index: 10;
    position: fixed;
    bottom: 15px;
    left: 15px;
    background-color: #34a0ff;
    color: white;
    font-size: 41px;
    transition: 0.3s;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 300%;
    border: none;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    animation: downToUp 1s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.addUser_btn:hover{
    cursor: pointer;
}


@media only screen and (min-width: 380px){
    .user_container{
        width: 300px;
    }
    .container{
        width: 310px;
    }
    .editWorker{
        width:260px;
    }
    .input_edit{
        width: 260px;
    }
    .select{
        width: 275px;
    }
    .edit_worker_btn{
        width: 130px;
    }
    .edit_worker_btn_cancel{
        width: 130px;
    }
}
@media only screen and (min-width: 430px){
    .user_container{
        width: 320px;
    }
    .container{
        width: 330px;
    }
    .editWorker{
        width:280px;
    }
    .input_edit{
        width: 280px;
    }
    .select{
        width: 295px;
    }
    .edit_worker_btn{
        width: 140px;
    }
    .edit_worker_btn_cancel{
        width: 140px;
    }
}
   