.editShift {
    width: 330px;
    font-size: 1.2em;
    border: none;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffffff;
    z-index: 999; 
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}


.close_icon{
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
}

.editShift form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 35px;
}

.input_div{
    display: flex;
    flex-direction: column;
    direction: rtl;
}
.input_div label{
    font-weight: 400;
    margin-right: 10px;
    margin-bottom: 5px;
    color: rgba(86, 86, 86, 0.881);
    direction: rtl;
    text-align: right;
}

.input_edit{
    direction: rtl;
    font-size: 17px;
    margin-bottom: 15px;
    padding: 13px;
    width: 250px;
    height: 15px;
    border-radius: 15px;
    border: none;
    font-family: 'Rubik', sans-serif;
    color: rgb(70, 70, 70);
    position: relative;
    background-color: #F3F3F3;
}

.input_time_start{
    text-align: right;
    appearance: none;
    -webkit-appearance:none;
    direction: rtl;
    font-size: 17px;
    margin-bottom: 15px;
    padding: 13px;
    width: 250px;
    height: 15px;
    border-radius: 15px;
    border: none;
    font-family: 'Rubik', sans-serif;
    color: rgb(70, 70, 70);
    position: relative;
    background-color: #F3F3F3;
    display: flex;
    flex-direction: column;
    align-items: right;
}
.input_time_end{
    text-align: right;
    appearance: none;
    -webkit-appearance:none;
    direction: rtl;
    font-size: 17px;
    margin-bottom: 15px;
    padding: 13px;
    width: 250px;
    height: 15px;
    border-radius: 15px;
    border: none;
    font-family: 'Rubik', sans-serif;
    color: rgb(70, 70, 70);
    position: relative;
    background-color: #F3F3F3;
    display: flex;
    flex-direction: column;
    align-items: right;
}

.btn_div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-bottom: -15px;
    direction: rtl;
}
.edit_shift_btn{
    margin-top: -10px;
    margin-bottom: 15px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 1.275rem;
    line-height: 1.45rem;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 50px;
    border: none;
    gap: 0.75rem;
    cursor: pointer;
    transition: all .6s ease;
    width: 280px;
    justify-content: center;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    height: 60px;
    background-color: #648ef8;
    color:#ffffff;
}

.select_workers_role{
    direction: rtl;
    font-size: 17px;
    margin-bottom: 15px;
    padding: 13px;
    width: 270px;
    height: 50px;
    border-radius: 15px;
    border: none;
    font-family: 'Rubik', sans-serif;
    color: rgb(70, 70, 70);
    position: relative;
    background-color: #F3F3F3;
}

@keyframes slideInAndFade {
    0% {
        transform: translateX(0);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@media only screen and (min-width: 450px){
    .editShift{
        width: 370px;
    }
}