.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 315px;
    margin-top: 85px;
    background-color: #dde0ef73;
    border-radius: 20px;
    padding: 10px;
    animation: slideInAndFade 0.5s ease-in-out;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    margin-bottom: 25px;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    backdrop-filter: blur(4px);
}


.shift_container{
    width: 290px;
    height: 30px;
    direction: rtl;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    margin: 10px;
    margin-top: 5px;
    padding: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-bottom: 12px;
    background-color: rgb(255, 255, 255);
}

.description{
    direction: ltr;
    font-size: 18px;
    margin-right: 5px;
}
.icon{
    font-size: 23px;
    color: gray;
}

.delete_edit_div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}

.edit_div_options{
    border-radius: 7px;
    width: 170px;
    height: auto;
    border: none;
    position: absolute;
    direction: ltr;
    left:-25px;
    top: 37px;
    z-index: 100;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(67, 71, 85, 0.27) 1px 1px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.15em 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    animation: slideInAndFade 0.2s ease-in-out;
}
.edit_div_options::before{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: 100%;
    left: 1.5em;
    border: .75rem solid transparent;
    border-top: none;
    border-bottom-color: rgb(255, 255, 255);
    filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
    cursor: pointer;
}
.edit_div_flex{
    margin: 8px;
    direction: ltr;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
}

.noShifts_div{
    font-size: 20px;
    font-weight: 500;
    color: rgb(112, 112, 112);
}

.icon_edit_select{
    color: rgb(98, 98, 98);
    font-size: 22px;
    cursor: pointer;
    margin-left: 10px;
}

@keyframes slideInAndFade {
    0% {
        transform: translateX(0);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.addShift_btn {
    width: 60px;
    height: 60px;
    z-index: 10;
    position: fixed;
    bottom: 15px;
    left: 15px;
    background-color: #34a0ff;
    color: white;
    border: none;
    font-size: 41px;
    transition: 0.3s;
    border-radius: 300%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    animation: downToUp 1s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.addShift_btn:hover{
    cursor: pointer;
}

/* - - - - - - - - edit shift - - - - - - -  */

.editShift{
    width: 270px;
    font-size: 1.2em;
    border: none;
    border-radius: 20px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.input_edit{
    margin: 10px;
    font-size: 18px;
    padding: 7px;
    border-radius: 10px;
    border: 2px solid rgba(138, 138, 138, 0.187);
    width: 260px;
    text-align: right;
    direction: rtl;
    appearance: none;
    -webkit-appearance:none;
    font-family: 'Rubik', sans-serif;
    margin-top: -3px;
}

.input_time_start{
    margin: 10px;
    font-size: 18px;
    padding: 7px;
    border-radius: 10px;
    border: 2px solid rgba(138, 138, 138, 0.187);
    width: 260px;
    text-align: right;
    direction: rtl;
    appearance: none;
    -webkit-appearance:none;
    font-family: 'Rubik', sans-serif;
}
.input_time_end{
    margin: 10px;
    font-size: 18px;
    padding: 7px;
    border-radius: 10px;
    border: 2px solid rgba(138, 138, 138, 0.187);
    width: 260px;
    text-align: right;
    direction: rtl;
    appearance: none;
    -webkit-appearance:none;
    font-family: 'Rubik', sans-serif;
}

.btn_div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: -15px;
    direction: rtl;
}
.edit_shift_btn{
    margin-left: 15px;
    margin-top: -10px;
    margin-bottom: 15px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 1.275rem;
    line-height: 1.25rem;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 50px;
    border: none;
    gap: 0.75rem;
    cursor: pointer;
    transition: all .6s ease;
    width: 130px;
    justify-content: center;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    background-color: #4f97d7a6;
    color:#ffffff;
}
.edit_shift_btn_cancel{
    margin-top: -10px;
    margin-bottom: 15px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 1.275rem;
    line-height: 1.25rem;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 50px;
    border: none;
    gap: 0.75rem;
    cursor: pointer;
    transition: all .6s ease;
    width: 130px;
    justify-content: center;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    background-color: rgb(255, 255, 255);
    color:#4f98d7ca;
    border: 1px solid #4f98d7ca;
}

@keyframes downToUp {
    0% {
        transform: translateY(130px);
        opacity: 1;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
  }
}

/* - - - - - - - media query - - - - - - - - */

@media only screen and (min-width: 380px){
    .container{
        width: 330px;
    }
    .shift_container{
        width: 310px;
    }
    .editShift{
        width: 290px;
    }
    .input_edit{
        width: 280px;
    }
    .input_time_start{
        width: 280px;
    }
    .input_time_end{
        width: 280px;
    }
    .edit_shift_btn{
        width: 140px;
    }
    .edit_shift_btn_cancel{
        width: 140px;
    }

}
@media only screen and (min-width: 430px){
    .container{
        width: 350px;
    }
    .shift_container{
        width: 330px;
    }
    .editShift{
        width: 310px;
    }
    .input_edit{
        width: 300px;
    }
    .input_time_start{
        width: 300px;
    }
    .input_time_end{
        width: 300px;
    }
    .edit_shift_btn{
        width: 150px;
    }
    .edit_shift_btn_cancel{
        width: 150px;
    }
}