.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 290px;
    padding: 17px;
    margin-top: 85px;
    background-color: #dde0ef73;
    border-radius: 20px;
    animation: slideInAndFade 0.5s ease-in-out;
    position: relative;
    overflow: hidden;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.userForm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.input_div{
    direction: rtl;
    margin-bottom: 13px;
}

.label{
    margin-right: 20px;
    font-size: 18px;
    color: rgb(103, 103, 103);
}

.input{
    margin: 10px;
    font-size: 20px;
    padding: 8px;
    border-radius: 12px;
    border: none;
    border: 1px solid rgba(203, 203, 203, 0.498);
    width: 275px;
    text-align: right;
    direction: rtl;
    appearance: none;
    -webkit-appearance:none;
    font-family: 'Rubik', sans-serif;
    margin-top: 5px;
    color: black;
}
.input::placeholder{
    color: rgb(185, 185, 185);
}

.input_time {
    margin: 10px;
    margin-top: 5px;
    font-size: 20px;
    padding: 8px;
    border-radius: 12px;
    border: 1px solid rgba(203, 203, 203, 0.498);
    background-color: white;
    width: 275px;
    text-align: right;
    direction: rtl;
    appearance: none;
    -webkit-appearance: none;
    height: 25px;
    line-height: 25px;
    font-family: 'Rubik', sans-serif;
}

.input_time::before {
    content: "9:00";
    color: rgb(185, 185, 185);
    direction: rtl;
    text-align: right;
    appearance: none;
    -webkit-appearance: none;
    font-family: 'Rubik', sans-serif;
    height: 25px;
    line-height: 25px;
}

.input_time2{
    margin: 10px;
    margin-top: 5px;
    font-size: 20px;
    padding: 8px;
    border-radius: 12px;
    border: none;
    background-color: white;
    border: 1px solid rgba(203, 203, 203, 0.498);
    width: 275px;
    text-align: right;
    direction: rtl;
    appearance: none;
    -webkit-appearance:none;
    font-family: 'Rubik', sans-serif;
    height: 25px;
    line-height: 25px;
}
.input_time2::before{
    content: "13:00";
    color: rgb(185, 185, 185);
    direction: rtl;
    text-align: right;
    appearance: none;
    -webkit-appearance:none;
    height: 25px;
    line-height: 25px;
    font-family: 'Rubik', sans-serif;
}

.btn_div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    direction: rtl;
}

.btn {
    margin-left: 20px;
    margin-top: 10px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 1.3rem;
    line-height: 1.25rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 30px;
    border: none;
    gap: 0.75rem;
    color: white;
    background-color: #63b6ff;
    cursor: pointer;
    transition: all .6s ease;
    width: 135px;
    justify-content: center;
    font-family: 'Rubik', sans-serif;
    background-color: #4f97d7a6;
    color:#ffffff;
    height: 55px;
}

.btn_cancel {
    margin-top: 10px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 1.3rem;
    line-height: 1.25rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 30px;
    gap: 0.75rem;
    cursor: pointer;
    transition: all .6s ease;
    width: 135px;
    height: 55px;
    justify-content: center;
    font-family: 'Rubik', sans-serif;
    background-color: rgb(255, 255, 255);
    color:#4f98d7ca;
    border: 1px solid #4f98d7ca;
}

@keyframes downToUp {
    0% {
        transform: translateY(80px);
    }
    100% {
        transform: translateY(0);
  }
}

@keyframes slideInAndFade {
    0% {
        transform: translateX(0);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

/* - - - - - - - media query - - - - - - - - */

@media only screen and (min-width: 380px){
    .container{
        width: 310px;
    }
    .btn{
        width: 145px;
    }
    .btn_cancel{
        width: 145px;
    }
    .input{
        width: 290px;
    }
    .input_time{
        width: 290px;
    }
    .input_time2{
        width: 290px;
    }
}
@media only screen and (min-width: 430px){
    .container{
        width: 330px;
    }
    .btn{
        width: 153px;
    }
    .btn_cancel{
        width: 153px;
    }
    .input{
        width: 310px;
    }
    .input_time{
        width: 310px;
    }
    .input_time2{
        width: 310px;
    }
}