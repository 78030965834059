.page_container {
  width: 100%;
  height: 100%;
  width: 100dvw;
}

.container{
  background-color:#5790FF;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 90vh;
  overflow: hidden;
}

.lock {
  height: 200px;
  width: 215px;
  position: absolute;
  top: 25px;
  margin-right: 50px;
  animation: slideLock 1s forwards; 
}

@keyframes slideLock {
  0% {
    top: -200px; /* start position above the screen */
  }
  100% {
    top: 0px; /* final position */
  }
}

.password_time_svg{
  width: 100%;
  position: absolute;
  top: 0;
  animation: slideDownCircle 1.5s forwards;
}
@keyframes slideDownCircle {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.logo{
  margin-left: 10px;
  margin-top: -20px;
  background-image: url('../../../public/fullLogo.png');
  background-repeat: no-repeat;
  background-size:cover;
  width: 200px;
  height: 65px;
  margin-bottom: 20px;
}

.form {
  border: none;
  margin-top: 90px;
  z-index: 34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  width: 100%;
  margin-bottom: -5px;
  height: 100%;
  max-height: 460px;
  position: fixed;
  bottom: 0;
}

/* buttons - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

.btn {
  z-index: 1;
  padding: 10px;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  border-radius: 40px;
  border: none;
  color: #ffffff;
  transition: 0.3s;
  cursor: pointer;
  width: 280px;
  background-color: #648ef8;
  font-family: 'Rubik', sans-serif;
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  direction: rtl;
}
.btn:hover {
  cursor: pointer;
}

.loading_icon{
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.login_div{
  z-index: 3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  direction: rtl;
  gap: 8px;
  color: #648ef8;
  font-family: 'Rubik', sans-serif;
  background-color: #254aa900;
  font-size: 20px;
  font-weight: 500;
  padding: 0px;
  margin-top: 3px;
}

.login_btn{
  border: none;
  color: #648ef8;
  font-family: 'Rubik', sans-serif;
  background-color: #254aa900;
  font-size: 20px;
  font-weight: 500;
  padding: 0px;
  border-bottom: 2px solid #648ef8;;
  cursor: pointer;
}

/* inputs - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

.password_div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 5px;
}

.show_password{
  color: rgba(86, 86, 86, 0.663);
  cursor: pointer;
  font-size: 30px;
  position: absolute;
  top: 7px;
  left: 10px;
}

.input_label_div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input{
  direction: rtl;
  font-size: 19px;
  margin-bottom: 30px;
  padding: 10px;
  width: 270px;
  border-radius: 25px;
  border: none;
  font-family: 'Rubik', sans-serif;
  color: rgb(70, 70, 70);
  position: relative;
  background-color: #F3F3F3;
}
.input::placeholder{
  color: #2e2e2e7c;
  direction: ltr;
}
.input:focus{
  outline: none;
}

.input_email{
  direction: rtl;
  font-size: 19px;
  margin-bottom: 30px;
  padding: 10px;
  width: 270px;
  border-radius: 25px;
  border: none;
  font-family: 'Rubik', sans-serif;
  color: rgb(70, 70, 70);
  position: relative;
  background-color: #F3F3F3;
  text-align: left;
}
.input_email::placeholder{
  color: #2e2e2e7c;
  direction: ltr;
  text-align: right;
}
.input_email:focus{
  outline: none;
}

/* - - - - - - - - - - - - - -  validations - - - - - - - - - - - - - -  */

.validation_fullname{
  position: absolute;
  color: rgba(242, 65, 65, 0.793);
  font-family: 'Rubik', sans-serif;
  margin-top: 38px;
  font-size: 17px;
}
.validation_email{
  position: absolute;
  color: rgba(242, 65, 65, 0.793);
  font-family: 'Rubik', sans-serif;
  margin-top: 38px;
  font-size: 17px;
}
.validation_username{
  position: absolute;
  color: rgba(242, 65, 65, 0.793);
  font-family: 'Rubik', sans-serif;
  margin-top: 38px;
  font-size: 17px;
}
.validation_password{
  position: absolute;
  color: rgba(242, 65, 65, 0.793);
  font-family: 'Rubik', sans-serif;
  margin-top: 38px;
  font-size: 17px;
}



/* - - - - - - - - - - - - - -  animations - - - - - - - - - - - - - -  */

@keyframes slideUpBtn {
  0% { transform: translateY(150px); } /* Start position outside of the view */
  100% { transform: translateY(0); } /* Final position at translateY value */
}
@keyframes slideUpRegisterBtn {
  0% { transform: translateY(-300px); }
  100% { transform: translateY(0); } 
}

/* - - - - - - - - - - - - - -  for desktop - - - - - - - - - - - - - -  */

@media only screen and (min-width: 450px) {
  .page_container {
    margin-top: 70px;
    border-radius: 40px;
    width: 440px;
  }
  .container{
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    border-radius: 40px;
    height: 770px;
    min-height: 0px;
  }
  .form{
    border-radius: 40px;
    position: relative;
    position: absolute;
    bottom: 0px;
    max-height: 530px;
  }
  .space_margin{
    margin-top: 10px;
  }
  .input{
    padding: 15px;
    width: 310px;
  }
  .input_email{
    padding: 15px;
    width: 310px;
  }
  .btn{
    margin-top: 20px;
    width: 330px;
    padding: 20px;
  }
  .lock{
    width: 220px;
    height: 220px;
    animation: none;
  }
  
  .validation_fullname{
    font-size: 19px;
    margin-bottom: -10px;
  }
  .validation_password{
    font-size: 19px;
    margin-bottom: -10px;
  }
  .validation_username{
    font-size: 19px;
    margin-bottom: -10px;
  }
  .validation_email{
    font-size: 19px;
    margin-bottom: -10px;
  }
}

@media only screen and (min-height: 720px) and (max-height: 760px){
  .form{
    max-height: 500px;
  }
  .input{
    font-size: 21px;
    padding: 12px;
  }
  .input_email{
    font-size: 21px;
    padding: 12px;
  }
  .btn{
    margin-top: 5px;
    padding: 15px;
  }
  .show_password{
    top: 11px;
  }
  .space_margin{
    margin-top: 0px;
  }
}

@media only screen and (min-height: 761px){
  .form{
    max-height: 530px;
  }
  .lock{
    width: 220px;
    height: 220px;
  }
  .input{
    font-size: 21px;
    padding: 12px;
  }
  .input_email{
    font-size: 21px;
    padding: 12px;
  }
  .btn{
    margin-top: 15px;
    padding: 15px;
  }
  .show_password{
    top: 11px;
  }
  .space_margin{
    margin-top: 5px;
  }
}