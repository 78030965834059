.container{
    margin-top: 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

@keyframes slideInAndFade {
    0% {
        transform: translateX(0);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
.day_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    padding-bottom: 5px;
    width: 290px;
    margin: 10px;
    border-radius: 15px;
    animation: slideInAndFade 0.5s ease-in-out;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    background-color: #f2f4ffe4;
    margin-bottom: 15px;
}

.h2_div{
    align-items: center;
    text-align: right;
    direction: rtl;
    width: 100%;
}

.h2{
    font-size: 22px;
    margin-top: -10px;
    margin-bottom: -2px;
    font-weight: 500;
    color: #287fcce2;
    margin-left: 160px;
}

.shift{
    width: 300px;
    font-size: 1.2em;
    border: none;
    margin-top: 10px;
    margin-bottom: 0px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.no_shifts_message{
    color: rgb(131, 131, 131);
    margin-top: 4px;
    font-size: 1.3em;
}

.bold_name{
    font-weight: 550;
    text-overflow: ellipsis;
    max-width: 110px;
    white-space: nowrap;
    overflow: hidden; 
}

.buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.btn {
    margin-top: 15px;
    margin: 5px;
    width: 87px;
    height: 70px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 13px;
    line-height: 1.25rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.25);
    gap: 0.75rem;
    color: rgb(65, 63, 63);
    background-color: #fff;
    cursor: pointer;
    transition: all .6s ease;
    justify-content: center;
}
.btn:hover {
transform: scale(1.02);
}

.add_btn{
    margin: 10px;
    margin-top: 0px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 1.275rem;
    line-height: 1.25rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.25);
    gap: 0.75rem;
    color: rgb(65, 63, 63);
    background-color: #fff;
    cursor: pointer;
    transition: all .6s ease;
    width: 230px;
    justify-content: center;
}
.add_btn:hover {
    transform: scale(1.02);
    }

.addShift{
    width: 280px;
    font-size: 1.2em;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    background-color: rgb(244, 247, 250);
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.addShift_btn{
    margin: 10px;
    margin-top: -10px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 1.275rem;
    line-height: 1.25rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.25);
    gap: 0.75rem;
    color: rgb(65, 63, 63);
    background-color: #fff;
    cursor: pointer;
    transition: all .6s ease;
    width: 230px;
    justify-content: center;
}
.addShift_btn:hover {
    transform: scale(1.02);
    }


.workers_list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 0px;
    width: 100%;
}
    
.workers_list p {
    margin-left: 0px;
}


.btn_delete{
    background-color: rgba(255, 0, 0, 0.684);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 9px;
    font-size: 16px;
    margin-left: 10px;
    margin-bottom: 15px;
}

.name_and_icon_div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.sb_icon{
    margin-top: 0px;
    margin-right: -2px;
}

.shift_name{
    margin: 10px;
    margin-right: -10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: right;
    direction: rtl;
}
.description_label{
    margin-right: 20px;
}

.under_icon{
    margin-right: 10px;
    font-size: 23px;
}

.loading_animation{
    display: flex;
    justify-content: center;
    align-items:center ;
    flex-direction: column;
}

.workers_showList{
    direction: rtl;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 5px;
}
.name{
    text-overflow: ellipsis;
    max-width: 110px;
    white-space: nowrap;
    overflow: hidden; 
}
.hours_message_div{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.all_data_div_clear{
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    gap: 5px;
    margin-left: 0px;
}

.role_div{
    text-align: right;
    color: rgb(125, 125, 125);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 135px;
}

.hours_message_div_SB{
    gap: 5px;
    margin-top: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.alert_div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-content: left;
}
.icon{
    color: rgba(119, 119, 119, 0.903);
    font-size: 21px;
    cursor: pointer;
}
.icon_message{
    color: rgba(119, 119, 119, 0.903);
    font-size: 21px;
    cursor: pointer;
    margin-right: 7px;
}

.all_data_div{
    margin: 8px;
    margin-right: 10px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.swal2_title{
    font-size: 28px;
}

.content{
    font-size: 22px;
}

@media only screen and (min-width: 300px){
    .h2{
        margin-left: 140px;
    }
    .shift_name{
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 300px;
        max-width: 300px;
        white-space: nowrap;
    }
    .description_label {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 310px;
        max-width: 310px;
        margin-right: 20px;
    }
}

@media only screen and (min-width: 370px){
    .day_container{
        width: 310px;
    }
    .h2{
        margin-left: 160px;
    }
    .shift{
        width: 320px;
    }
    .shift_name{
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 320px;
        max-width: 320px;
        white-space: nowrap;
    }
    .description_label {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 310px;
        max-width: 310px;
        margin-right: 20px;
    }
    .role_div{
        width: 155px;
    }
}
@media only screen and (min-width: 430px){
    .day_container{
        width: 320px;
    }
    .shift{
        width: 340px;
    }
    .shift_name{
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 335px;
        max-width: 335px;
        white-space: nowrap;
    }
    .description_label {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 310px;
        max-width: 310px;
        margin-right: 20px;
    }
    .role_div{
        width: 175px;
    }
}

/* - - - - - - - - - - - - - - - expend icon animation - - - - - - - - - - - -  */

/*------ Settings ------*/
.icon_container {
    --color: #287fcc6a;
    --size: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-size: var(--size);
    user-select: none;
    fill: var(--color);
    margin-right: 25px;
  }
  
  .icon_container .chevron_down {
    position: absolute;
    animation: keyframes-return .5s;
  }
  
  /* ------ On check event ------ */
  .icon_container input:checked ~ .chevron_down {
    animation: keyframes-rotate .5s;
    transform: rotate(180deg);
  }
  
  /* ------ Hide the default checkbox ------ */
  .icon_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* ------ Animation ------ */
  @keyframes keyframes-rotate {
    0% {
      transform: rotate(0deg);
      opacity: 0;
    }
  
    100% {
      transform: rotate(-180deg);
    }
  }
  
  @keyframes keyframes-return {
    0% {
      transform: rotate(-180deg);
      opacity: 0;
    }
  
    100% {
      transform: rotate(0deg);
    }
  }