.error {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  width: 320px;
  padding: 12px;
  display: flex;
  flex-direction: row-reverse; /* Reversed direction */
  align-items: center;
  justify-content: start;
  background: #EF665B;
  border-radius: 8px;
  box-shadow: 0px 0px 5px -3px #111;
  position: absolute;
  bottom: 15px;
  direction: rtl;
  animation: downToUpAlert 1s ease-in-out;
}

.error__icon {
  width: 20px;
  height: 20px;
  transform: translateY(-2px);
  margin-right: 0px; /* Adjusted margin */
}

.error__icon path {
  fill: #fff;
}

.error__title {
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  direction: ltr; /* Text direction left-to-right */
  margin-right: 8px; /* Adjusted margin */
}

@keyframes downToUpAlert {
  0% {
      transform: translateY(130px);
      opacity: 1;
  }
  100% {
      transform: translateY(0);
      opacity: 1;
  }
}
