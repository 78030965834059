.nav_container{
  height: 60px;
  background-color: #ffffff;
  top: 0;
  left: 0;
  width: 100%;
  position:fixed;
  z-index: 999;
  color: white;
  font-size: 25px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px; */
  border-bottom: 0px solid rgba(128, 128, 128, 0.174);
  font-weight: 600;
  direction: rtl;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: 'Rubik', sans-serif;

  background: rgba(255, 255, 255, 0.51);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(15.0px);
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(200%);
  }
  to {
    transform: translateX(0);
  }
}

.nav_btn_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  -webkit-appearance: none;
}


.logo_div{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  -webkit-appearance: none;
}

.name{
  font-size: 21px;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  color: #34a0ff;
  margin-top: 3px;
}

.hamburger{
  cursor: pointer;
  font-size: 30px;
  margin-right: 12px;
  color: #34a0ff;
  margin-top: 5px;
}

.nav_container img{
  font-size: 25px;
  border:none;
  margin-top: 6px;
  width: 24px;
  height: 30px;
}

.logo{
  width: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-bottom: 5px;
}
  
.spacer {
  flex-grow: 1;
}

.sideBar {
  position: fixed;
  right: 0;
  top: 0;
  width: 290px;
  height: 100vh;
  color: #34a0ff;
  animation: slideInFromRightName 0.5s ease-in-out;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: 0.3s ease-in-out;

  background: rgba(255, 255, 255, 0.599);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(14.7px);
  -webkit-backdrop-filter: blur(14.7px);

}

.closed_sideBar{
  transform: translateX(100%);
}
.closed_blur_back{
  display: none;
}

.blur_back {
  position: fixed;
  top: 0;
  left: 0vw;
  width: 100vw;
  height: 100vh;
  animation: opacity 0.5s ease-in-out;
  background: rgba(207, 207, 207, 0.66);
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(4px);
}


.upper_sidebar_div{
  border-top-left-radius: 20px;
  width: 100%;
  height: 180px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.close_icon{
  position: absolute;
  top: 15px;
  left: 15px;
  color: white;
  color: rgb(129, 129, 129);
  cursor: pointer;
}

.name_upper_sidebar{
  color: rgb(114, 112, 112);
  font-size: 28px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.avatar_img{
  background-color: #25a1ff;
  width: 100px;
  height: 140px;
}

.buttons_div{
  direction: rtl;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: right;
  margin: 15px;
}

.buttons_container{
  margin-top: -35px;
}

.text_and_icon{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
}

.line{
  border: 0.5px solid rgba(202, 202, 202, 0.308);
  margin-top: -5px;
}

.links{
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  color: #333333cb;
  background-color: #ffffff00;
  border: none;
  font-family: 'Rubik', sans-serif;
}

.icon_link{
  margin-bottom: -5px;
  background-color: #ffffff00;
  border: none;
}

.label{
  text-decoration: none;
  font-size: 16px;
  color: rgb(145, 145, 145);
  color: #333333cb;
  cursor: pointer;
  font-weight: 400;
}

.icon{
  margin-left: 18px;
  margin-right: 5px;
  font-size: 23px;
  color: #333333a1;
  cursor: pointer;
}

@keyframes opacity {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@keyframes slideInFromRightName {
  0% {
      transform: translateX(400px);
      opacity: 1;
  }
  100% {
      transform: translateX(0);
      opacity: 1;
  }
}


/* - - - - - - - - - - - swal - - - - - - - - - - - - - - - - - -  */

.confirm_button_class {
  background-color: #004d9f;
  color: #ffffff;
  border-radius: 30px;
  font-weight: 500;

  background-color: #d2e9ff;
  color:#81c4ff;
}

.cancel_button_class{
  background-color: rgb(223, 239, 255);
  color: #004d9f;
  border: 1px solid #004d9f;
  border-radius: 30px;
  font-weight: 500;
}