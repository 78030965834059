:root{
    --main-color: #34a0ff;
}

.container{
    direction: ltr;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.container_disabled{
    direction: ltr;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    background:#ffffff;
    opacity: 0.2;
    filter: alpha(opacity=10);
    pointer-events:none;
}

.addShift_btn_disabled{
    margin: 10px;
    margin-top: -10px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 1.275rem;
    line-height: 1.25rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 0.5rem;
    border: none;
    gap: 0.75rem;
    color: rgb(65, 63, 63);
    background-color: var(--main-color);
    cursor: pointer;
    transition: all .6s ease;
    width: 230px;
    justify-content: center;
    animation: slideInAndFade 0.5s ease-in-out;
    background:#ffffff;
    opacity: 0.2;
    filter: alpha(opacity=10);
    pointer-events:none;
}

@keyframes slideInFromRightName {
    0% {
        transform: translateX(400px);
        opacity: 1;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
  }
}

@keyframes slideInAndFade {
    0% {
        transform: translateX(0);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.day_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    width: 280px;
    margin: 20px;
    background: rgba(255, 255, 255, 0.579);
    background-color: #f2f4ffe4;
    border-radius: 15px;
    animation: slideInAndFade 0.5s ease-in-out;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;

}

.h2{
    font-size: 22px;
    margin-top: -10px;
    margin-bottom: 5px;
    font-weight: 500;
    color: #287fcce2;
    margin-left: 160px;
}

.shift{
    width: 280px;
    font-size: 1.2em;
    border: none;
    border-radius: 10px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    background-color: rgba(231, 234, 238, 0.468);
}
.shift_createWeek{
    width: 265px;
    font-size: 1.2em;
    border: none;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -10px;
    padding: 12px;
    background-color: rgba(227, 233, 242, 0);
}

.addWroker_div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 270px;
    margin-bottom: -15px;
}
.plus_btn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    width: 15px;
    height: 15px;
    color: rgba(101, 101, 101, 0.889);
    cursor: pointer;
    margin-top: 5px;
}
.select_choose_worker{
    direction: rtl;
    border-radius: 7px;
    padding: 3px;
    margin-top: 5px;
    border: 1px solid rgba(101, 101, 101, 0.245);
    margin-bottom: 5px;
}
.select_choose_worker:optional{
    cursor: pointer;
}

.buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.btn {
    margin-top: 15px;
    margin: 5px;
    width: 295px;
    height: auto;
    padding: 0.8rem 2.4rem;
    font-size: 20px;
    line-height: 1.25rem;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    border-radius: 0.5rem;
    border: none;
    gap: 0.75rem;
    background-color: var(--main-color);
    background-color: #d2e9ff;
    color:#2880cc;
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    margin-bottom: 0px;
    height: 40px;
    align-items: center;
}

.specific_shift_div{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 40px;
    font-size: 21px;
    font-family: 'Rubik', sans-serif;
    border-radius: 12px;
    border: 1px solid rgb(181, 181, 181);
    direction: rtl;
    cursor: pointer;
    text-align: right;
    padding:5px 5px;
    appearance: none;
    -webkit-appearance:none;
    background-color: #d2e9ff;
    color:#2880cc;
    border: none;
    font-weight: 500;
    text-align: center !important;
    position: relative;
    margin-bottom: -5px;
}

.specific_shift_div select{
    position: absolute;
    width: 290px;
    height: 50px;
    font-size: 21px;
    font-family: 'Rubik', sans-serif;
    border-radius: 12px;
    border: 1px solid rgb(181, 181, 181);
    direction: rtl;
    cursor: pointer;
    text-align: right;
    padding:5px 5px;
    appearance: none;
    -webkit-appearance:none;
    background-color: #d2e9ff00;
    color:#287fcc00;
    border: none;
    font-weight: 500;
    text-align: center !important;
}

.icon_publish {
    font-size: 25px;
    transform: scaleX(-1);
}
.icon_publishd {
    font-size: 25px;
}

.add_btn{
    margin: 10px;
    margin-top: 0px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 1.275rem;
    line-height: 1.25rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.25);
    gap: 0.75rem;
    color: rgb(65, 63, 63);
    background-color: #fff;
    cursor: pointer;
    transition: all .6s ease;
    width: 230px;
    justify-content: center;
}
.add_btn:hover {
    transform: scale(1.02);
}

.addShift{
    width: 285px;
    font-size: 1.2em;
    border: none;
    border-radius: 10px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: -10px;
    position: relative;
}

.addShift_div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.hour_and_input{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.hour_and_input{
    margin-right: 20px;
    font-family: 'Rubik', sans-serif;
}

.input_time_start{
    border-radius: 12px;
    margin: 10px;
    font-size: 18px;
    border-radius: 15px;
    padding: 8px;
    border: 1px solid rgba(203, 203, 203, 0.498);
    width: 270px;
    text-align: right;
    direction: rtl;
    appearance: none;
    -webkit-appearance:none;
    font-family: 'Rubik', sans-serif;
    background-color: white;
}
.input_time_start::before{
    content: 'התחלה';
    color: #9d9d9d;
    direction: ltr;
    appearance: none;
    -webkit-appearance:none;
    font-family: 'Rubik', sans-serif;
    background-color: white;
}

.input_time_end{
    margin: 10px;
    font-size: 18px;
    padding: 8px;
    border-radius: 15px;
    border: 1px solid rgba(203, 203, 203, 0.498);
    width: 270px;
    text-align: right;
    direction: rtl;
    appearance: none;
    -webkit-appearance:none;
    font-family: 'Rubik', sans-serif;
    background-color: white;
}
.input_time_end::before{
    content: 'סיום';
    color: #9d9d9d;
    direction: ltr;
    appearance: none;
    -webkit-appearance:none;
    font-family: 'Rubik', sans-serif;
    background-color: white;
}

.input{
    margin: 10px;
    font-size: 18px;
    padding: 8px;
    border-radius: 15px;
    border: 1px solid rgba(203, 203, 203, 0.498);
    width: 270px;
    text-align: right;
    direction: rtl;
    font-family: 'Rubik', sans-serif;
}

.addShift_btn{
    margin-top: -10px;
    margin-bottom: 15px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 1.275rem;
    line-height: 1.25rem;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 12px;
    border: none;
    gap: 0.75rem;
    cursor: pointer;
    transition: all .6s ease;
    width: 130px;
    justify-content: center;
    background-color: #c1e1ff;
    color:#2880cc;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    margin-right:20px;
    height: 55px;
    border-radius: 30px;
}
.addShift_cancel_btn{
    margin-top: -10px;
    margin-bottom: 15px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 1.275rem;
    line-height: 1.25rem;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 30px;
    border: none;
    gap: 0.75rem;
    cursor: pointer;
    transition: all .6s ease;
    width: 130px;
    justify-content: center;
    background-color: rgba(255, 92, 92, 0.76);
    color:rgb(255, 255, 255);
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    background-color: rgb(255, 255, 255);
    color:#2880cc;
    border: 1px solid #2880cc;
    height: 55px;
}

.ai_div{
    margin-top: 20px;
    margin-bottom: -10px;
}

.workers_list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 0px;
    width: 100%;
    border-top: 1px solid rgba(101, 101, 101, 0.245);
}
    
.workers_list p {
    margin-left: 0px;
}


.btn_delete{
    color: rgba(227, 86, 86, 0.989);
    border: none;
    font-size: 25px;
    background-color: #25d36500;
    margin-top: 4px;
}
.btn_delete:hover{
    cursor: pointer;
}

.shift_description{
    font-size: 19px;
    margin-bottom: 15px;
}

.shift_description_createWeek{
    font-size: 18px;
}

.no_shifts_messge{
    color: rgb(111, 111, 111);
    margin-top: 4px;
    font-size: 1.3em;
}

.shift_data_p{
    margin: 12px
}

.loadingWorkers{
    margin: 15px;
    margin-left: 120px;
}

.workers_list_delete{
    direction: ltr;
    border-top: 1px solid rgba(101, 101, 101, 0.245);
    width: 100%;
    display: flex;
    flex-direction: column;
}

.names{
    direction: rtl;
    margin: 10px;
    margin-right: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 130px;
}

.nameAndDelete{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0px solid rgba(101, 101, 101, 0.245);
}

.btn_chose{
    margin: 15px;
    background-color: rgb(46, 181, 29);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 9px;
    width: 60px;
    cursor: pointer;
    transition: 0.3s;
    font-size: 15px;
    
}
.btn_chose:hover{
    background-color: rgb(30, 144, 14);
}

.btn_chosen{
    width: 60px;
    margin: 15px;
    background-color: rgba(230, 58, 20, 0.838);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 9px;
    cursor: pointer;
    font-size: 15px;
    transition: 0.3s;
}
.btn_chosen:hover{
    background-color: rgba(196, 48, 15, 0.956);
}

.add_specific_worker_div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.add_specific_worker_btn{
    width: 60px;
    margin: 15px;
    background-color: rgba(165, 165, 165, 0.88);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 9px;
    cursor: pointer;
    font-size: 15px;
    transition: 0.3s;
    margin-right: 30px;
    font-weight: 600;
    background-color: #d2e9ff;
    color:#2880cc;
}

.add_specific_worker_select{
    margin: 10px;
    font-size: 18px;
    padding: 5px;
    border-radius: 5px;
    border: none;
    border: 1px solid rgba(0, 0, 0, 0.25);
    width: 150px;
    text-align: right;
    direction: rtl;
    font-family: 'Rubik', sans-serif;
}

.loadingWorkers_manger_page{
    margin: 15px;
}
.first_publish_div{
    margin-top: 90px;
}
.first_addShift_btn{
    margin: 10px;
    margin-top: -10px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 1.275rem;
    line-height: 1.25rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.25);
    gap: 0.75rem;
    color: rgb(65, 63, 63);
    background-color: var(--main-color);
    color: white;
    cursor: pointer;
    transition: all .6s ease;
    width: 320px;
    justify-content: center;
}
.first_addShift_btn:hover {
    transform: scale(1.02);
}
.publish_div_createWeek{
    position: absolute;
    bottom: 30px;
    z-index: 900;
    position:fixed;
}

.publish_div_createWeek button{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-top: -10px;
    margin-bottom: 0px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 1.275rem;
    line-height: 1.25rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 12px;
    border: none;
    gap: 0.75rem;
    color: white;
    cursor: pointer;
    transition: all .6s ease;
    width: 300px;
    justify-content: center;
    animation: downToUp 1s ease-in-out;
    background-color:#68b5f9;
    font-family: 'Rubik', sans-serif;
}

.edit_div_createWeek{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 90px;
    animation: slideInAndFade 0.5s ease-in-out;
}
.edit_div_createWeek button{
    margin-top: -10px;
    margin-bottom: 10px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 1.275rem;
    line-height: 1.25rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 0.5rem;
    border: none;
    gap: 0.75rem;
    color: rgb(65, 63, 63);
    background-color: var(--main-color);
    color: white;
    cursor: pointer;
    transition: all .6s ease;
    width: 320px;
    justify-content: center;
}

.publish_div{
    position: absolute;
    bottom: 30px;
    z-index: 900;
    position:fixed;
}

.publish_div button{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-top: -10px;
    margin-bottom: 0px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 1.275rem;
    line-height: 1.25rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 12px;
    border: none;
    gap: 0.75rem;
    color: white;
    cursor: pointer;
    transition: all .6s ease;
    width: 300px;
    justify-content: center;
    animation: downToUp 1s ease-in-out;
    background-color:#68b5f9;
}

.published_div{
    position: absolute;
    bottom: 30px;
    z-index: 900;
    position:fixed;
}

.published_div button{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-bottom: 0px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 16px;
    line-height: 1.25rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 12px;
    gap: 0.75rem;
    cursor: pointer;
    transition: all .6s ease;
    width: 300px;
    justify-content: center;
    animation: downToUp 1s ease-in-out;
    font-family: 'Rubik', sans-serif;
    background-color: #EDFBD8;
    border: 1px solid #84D65A;
    box-shadow: 0px 0px 5px -3px #111;
    color: #2B641E;
}

.workers_showList{
    direction: ltr;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    width: 100%;
    margin-top: 5px;
    border-top: 1px solid rgba(101, 101, 101, 0.245);
}
.workers_showList > :last-child {
    grid-column-start: 2;
}

.home_btn{
    cursor: pointer;
}

.icon_delete{
    margin-left: 18px;
    font-size: 23px;
    color: rgba(227, 86, 86, 0.989);
}
.icon_delete:hover{
    color: rgba(227, 86, 86, 0.989);
    cursor: pointer;
}
.icon_edit{
    margin-left: 15px;
    color: rgba(119, 119, 119, 0.903);
    font-size: 23px;
    cursor: pointer;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2); /* Scale up at 50% of the animation */
    }
    100% {
        transform: scale(1);
    }
}
.icon_message_alert{
    margin-left: 18px;
    color: rgba(119, 119, 119, 0.903);
    font-size: 23px;
    cursor: pointer;
    animation: pulse 1s infinite;
    animation: slideInAndFade 0.7s ease-in-out;
}
.icon_add{
    margin-left: 18px;
    margin-top: 3px;
    font-size: 23px;
    color: green;
    cursor: pointer;
}
.icon_add:hover{
    color: rgb(0, 87, 0);
}

.label_edit_select{
    position: relative;
}

.edit_div_options{
    border-radius: 7px;
    width: 170px;
    height: auto;
    border: none;
    position: absolute;
    direction: ltr;
    left:-13px;
    top: 37px;
    z-index: 100;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(67, 71, 85, 0.27) 1px 1px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.15em 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    animation: slideInAndFade 0.2s ease-in-out;
}
.edit_div_options::before{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: 100%;
    left: 1.5em;
    border: .75rem solid transparent;
    border-top: none;
    border-bottom-color: rgb(255, 255, 255);
    filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
    cursor: pointer;
}
.edit_div_flex{
    margin: 8px;
    direction: ltr;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.icon_edit_select{
    color: rgba(119, 119, 119, 0.903);
    font-size: 21px;
    cursor: pointer;
    margin-left: 10px;
}

@keyframes downToUp {
    0% {
        transform: translateY(130px);
        opacity: 1;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
  }
}

.edit_week_btn {
    width: 60px;
    height: 60px;
    z-index: 10;
    position: fixed;
    bottom: 15px;
    left: 15px;
    background-color: #34a0ff;
    color: white;
    border: none;
    transition: 0.3s;
    border-radius: 300%;
    font-size: 25px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    animation: downToUp 1s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.edit_week_btn:hover{
    cursor: pointer;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    backdrop-filter: blur(4px);
}



/* - - - - - - - -alert css - - -  - - - - - - - */

.swal2_popup {
    border-radius: 8px;
}

.swal2_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
}

  /* Style for the form */
.swal2_content form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
}

  /* Style for the input fields */
.swal2_content input[type='time'] {
    border: 1.5px solid rgba(175, 175, 175, 0.413);
    width: 120px;
    padding: 8px;
    border-radius: 4px;
    font-size: 15px;
    height: 15px;
    -webkit-appearance: none;
    background-color: rgba(245, 242, 242, 0.696);
}

.swal2_content div{
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

  /* Style for the labels */
.swal2_content label {
    font-size: 22px;
    margin-left: 8px;
}

.swal2_content p {
    direction: rtl;
    border: none;
    background-color: #25d36500;
    color: rgb(62, 62, 62);
    border-radius: 10px;
    padding: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: right;
    font-size: 24px;
}

.swal2_content h2 {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 5px;
}

.swal2_title{
    font-size: 24px;
    margin-bottom: -5px;
}

.swal2_input{
    margin-top: 0px;
}


.AI_content{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: end;
    text-align: right;
}

.AI_content div{
    margin: 10px;
    font-size: 20px;
}





@media only screen and (min-width: 300px){
    .h2{
        margin-left: 140px;
    }
}

@media only screen and (min-width: 370px){
    .day_container{
        width: 300px;
    }
    .h2{
        margin-left: 160px;
    }
    .shift{
        width: 300px;
    }
    .shift_createWeek{
        width: 285px;
    }
    .published_div button{
        width: 320px;
        font-size: 17px;
    }
    .btn{
        width: 315px;
    }
    .specific_shift_div{
        width: 300px;
    }
    .specific_shift_div select{
        width: 310px;
    }
    .addShift{
        width: 305px;
    }
    .publish_div_createWeek button{
        width: 320px;
    }
    .input{
        width: 290px;
    }
    .input_time_start{
        width: 290px;
    }
    .input_time_end{
        width: 290px;
    }
    .addShift_btn{
        width: 140px;
    }
    .addShift_cancel_btn{
        width: 140px;
    }
    .edit_div_createWeek button{
        width: 320px;
    }
    .add_specific_worker_btn{
        margin-right: 48px;
    }
    .publish_div button{
        width: 320px;
    }
    .message{
        width: 320px;
    }
    .ai_btn{
        width: 340px;
    }
}
@media only screen and (min-width: 430px){
    .day_container{
        width: 320px;
    }
    .h2{
        margin-left: 175px;
    }
    .shift{
        width: 320px;
    }
    .shift_createWeek{
        width: 310px;
    }
    .published_div button{
        width: 340px;
        font-size: 18px;
    }
    .btn{
        width: 335px;
    }
    .specific_shift_div{
        width: 320px;
    }
    .specific_shift_div select{
        width: 330px;
    }
    .addShift{
        width: 325px;
    }
    .publish_div_createWeek button{
        width: 340px;
    }
    .input{
        width: 270px;
    }
    .input_time_start{
        width: 270px;
    }
    .input_time_end{
        width: 270px;
    }
    .addShift_btn{
        width: 135px;
    }
    .addShift_cancel_btn{
        width: 135px;
    }
    .edit_div_createWeek button{
        width: 360px;
    }
    .add_specific_worker_btn{
        margin-right: 65px;
    }
    .publish_div button{
        width: 340px;
    }
    .message{
        width: 340px;
    }
    .ai_btn{
        width: 360px;
    }
}
@media only screen and (min-width: 900px){
    .specific_shift_div select{
        color:#2880cc;
    }
}
