:root{
    --main-color: #34a0ff;
}

 /* - - - - - - - main component - - - - - - -  */

.container{
    direction: ltr;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-family: 'Rubik', sans-serif;
}

.published_div{
    position: absolute;
    bottom: 30px;
    z-index: 900;
    position:fixed;
}

.published_div button{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-bottom: 0px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 17px;
    line-height: 1.25rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 12px;
    gap: 0.75rem;
    cursor: pointer;
    transition: all .6s ease;
    width: 300px;
    justify-content: center;
    animation: downToUp 1s ease-in-out;
    font-family: 'Rubik', sans-serif;
    background-color: #EDFBD8;
    border: 1px solid #84D65A;
    box-shadow: 0px 0px 5px -3px #111;
    color: #2B641E;
}

.icon_publish {
    font-size: 26px;
    transform: scaleX(-1);
}
.icon_publishd {
    font-size: 25px;
}

.btn_ai{
    display: flex;
    border-radius: 10px;
    border: 3px solid #eaf5ff;
    cursor: pointer;
    transition: all .6s ease;
    justify-content: center;
    align-items: center;
    font-family: 'Rubik', sans-serif;
    height: 45px;
    font-size: 23px;
    font-weight: 500;
    gap: 20px;
    z-index: 20;
    width: 320px;
    margin-top: 10px;
    margin-bottom: -5px;
    background-color: #d2e9ff;
    color:#2880cc;
    animation: slideInAndFade 0.5s ease-in-out;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}
.btn_ai label{
    font-size: 1.275rem;
    font-weight: 500;
    direction: rtl;
}

.btn_ai_block{
    display: flex;
    border-radius: 10px;
    border: 3px solid #eaf5ff;
    cursor: pointer;
    transition: all .6s ease;
    justify-content: center;
    align-items: center;
    font-family: 'Rubik', sans-serif;
    height: 45px;
    font-size: 23px;
    font-weight: 500;
    gap: 20px;
    z-index: 20;
    width: 320px;
    margin-top: 10px;
    margin-bottom: -5px;
    background-color: #d2e9ff;
    color:#2880cc;
    animation: slideInAndFade 0.5s ease-in-out;
    display: block;
}
.btn_ai_block label{
    font-size: 1.275rem;
    font-weight: 500;
    direction: rtl;
}

.publish_div{
    position: absolute;
    bottom: 30px;
    z-index: 900;
    position:fixed;
}
.publish_div button{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    border-radius: 10px;
    border: none;
    color: white;
    cursor: pointer;
    transition: all .6s ease;
    width: 300px;
    justify-content: center;
    align-items: center;
    animation: downToUp 1s ease-in-out;
    background-color:#68b5f9;
    font-family: 'Rubik', sans-serif;
    height: 50px;
    font-size: 1.275rem;
    font-weight: 500;
    gap: 10px;
}

.container_disabled{
    margin-top: 25px;
    direction: ltr;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    background:#ffffff;
    opacity: 0.05;
    filter: alpha(opacity=10);
    pointer-events:none;
}

/* - - - - - - -day component - - - - - -  */

.day_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    width: 280px;
    margin: 20px;
    background-color: #f2f4ffe4;
    border-radius: 15px;
    animation: slideInAndFade 0.5s ease-in-out;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.div_h2_day{
    display: flex;
    flex-direction: row;
    text-align: right;
    width: 100%;
    direction: rtl;
}

.h2{
    font-size: 22px;
    margin-top: -10px;
    margin-bottom: 5px;
    font-weight: 500;
    color: #287fcce2;
}

.no_shifts_messge{
    color: rgb(111, 111, 111);
    margin-top: 4px;
    font-size: 1.3em;
}

/* - - - - - - - shift component - - - - - -  */

.shift{
    width: 295px;
    font-size: 1.2em;
    border: none;
    border-radius: 10px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
}

.shift_description{
    font-size: 20px;
    margin-bottom: 15px;
    margin-top: 7px;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-weight: 450;
    color: rgba(28, 28, 31, 0.659);
}

/* - - - - - - - workers component - - - - - -  */

.workers_list_delete{
    direction: ltr;
    /* border-top: 1px solid rgba(101, 101, 101, 0.245); */
    width: 100%;
    display: flex;
    flex-direction: column;
}

.nameAndDelete{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.label_edit_select{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 70px;
}

.icon_edit{
    margin-left: 8px;
    color: rgba(119, 119, 119, 0.903);
    font-size: 23px;
    cursor: pointer;
}

.edit_div_options{
    border-radius: 7px;
    width: 175px;
    height: auto;
    border: none;
    position: absolute;
    direction: ltr;
    left:-20px;
    top: 37px;
    z-index: 901;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(67, 71, 85, 0.27) 1px 1px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.15em 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    animation: slideInAndFade 0.2s ease-in-out;
}
.edit_div_options::before{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: 100%;
    left: 1.5em;
    border: .75rem solid transparent;
    border-top: none;
    border-bottom-color: rgb(255, 255, 255);
    filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
    cursor: pointer;
}
.edit_div_flex{
    margin: 8px;
    direction: ltr;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.icon_edit_select{
    color: rgba(119, 119, 119, 0.903);
    font-size: 21px;
    cursor: pointer;
    margin-left: 10px;
}

.icon_message_alert{
    color: rgba(119, 119, 119, 0.903);
    font-size: 23px;
    cursor: pointer;
    animation: pulse 1s infinite;
    animation: slideInAndFade 0.7s ease-in-out;
}

.name_role_div{
    display: flex;
    flex-direction: row;
    align-items: center;
    direction: rtl;
}
.role_sb_div{
    text-align: right;
    color: rgb(125, 125, 125);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 145px;
}

.names{
    direction: rtl;
    margin: 10px;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 125px;
}

.nameAndDelete{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0px solid rgba(101, 101, 101, 0.245);
}

.add_available_worker_div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 265px;
    height: 30px;
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
    border-radius: 10px;
    border: 1px solid rgb(181, 181, 181);
    direction: rtl;
    cursor: pointer;
    text-align: right;
    padding:5px 5px;
    appearance: none;
    -webkit-appearance:none;
    background-color: #d2e9ff;
    color:#2880cc;
    border: none;
    font-weight: 500;
    text-align: center !important;
    position: relative;
    margin-bottom: 12px;
    margin-top: 10px;
}

.add_specific_worker_div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 265px;
    height: 30px;
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
    border-radius: 10px;
    border: 1px solid rgb(181, 181, 181);
    direction: rtl;
    cursor: pointer;
    text-align: right;
    padding:5px 5px;
    appearance: none;
    -webkit-appearance:none;
    background-color: #d2e9ff;
    color:#2880cc;
    border: none;
    font-weight: 500;
    text-align: center !important;
    position: relative;
    margin-bottom: 10px;
}

.add_specific_worker_select{
    position: absolute;
    width: 275px;
    height: 40px;
    font-size: 21px;
    font-family: 'Rubik', sans-serif;
    border-radius: 10px;
    direction: rtl !important;
    cursor: pointer;
    padding:5px 5px;
    appearance: none;
    -webkit-appearance:none;
    background-color: #d2e9ff00;
    color:#ff010100;
    border: none;
    font-weight: 500;
    text-align: right !important;
}
.add_specific_worker_select option{
    font-family: 'Rubik', sans-serif;
    direction: rtl !important;
    color: rgb(0, 0, 0);
    appearance: none;
    -webkit-appearance:none;
}


/* - - - - - animations - - - - -  */

@keyframes slideInFromRightName {
    0% {
        transform: translateX(400px);
        opacity: 1;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
  }
}

@keyframes magicEffect {
    0%, 100% {
      transform: scale(1) rotate(0);
    }
    75% {
      transform: scale(1) rotate(40deg);
    }
    25% {
      transform: scale(1) rotate(-80deg);
    }
}

@keyframes slideInAndFade {
    0% {
        transform: translateX(0);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2); /* Scale up at 50% of the animation */
    }
    100% {
        transform: scale(1);
    }
}

@keyframes downToUp {
    0% {
        transform: translateY(130px);
        opacity: 1;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
  }
}

/* - - - - - swal alert - - - - -  */

.swal2_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
}

  /* Style for the form */
.swal2_content form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
}

  /* Style for the input fields */
.swal2_content input[type='time'] {
    border: 1.5px solid rgba(175, 175, 175, 0.413);
    width: 120px;
    padding: 8px;
    border-radius: 4px;
    font-size: 15px;
    height: 15px;
    -webkit-appearance: none;
    background-color: rgba(245, 242, 242, 0.696);
}

.swal2_content div{
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

  /* Style for the labels */
.swal2_content label {
    font-size: 22px;
    margin-left: 8px;
}

.swal2_content p {
    direction: rtl;
    border: none;
    background-color: #25d36500;
    color: rgb(62, 62, 62);
    border-radius: 10px;
    padding: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: right;
    font-size: 24px;
}

.swal2_content h2 {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 5px;
}

.swal2_input{
    margin-top: 0px;
}

.swal2_popup {
    border-radius: 8px;
}

.swal2_title{
    font-size: 24px;
    margin-bottom: -5px;
}

.AI_content{
    text-align: right;
    direction: rtl;
}

.roleSelect{
    border-radius: 10px;
    border: 2px solid rgb(215, 215, 215);
    font-family: 'Rubik', sans-serif;
    font-size: 22px;
    padding: 2px;
    text-align: right;
    direction: rtl;
}


/* - - - - - loading animation - - - - -  */

.three-body {
    --uib-size: 35px;
    --uib-speed: 0.8s;
    --uib-color: var(--main-color);
    position: relative;
    display: inline-block;
    height: var(--uib-size);
    width: var(--uib-size);
    animation: spin78236 calc(var(--uib-speed) * 2.5) infinite linear;
   }
   
   .three-body__dot {
    position: absolute;
    height: 100%;
    width: 30%;
   }
   
   .three-body__dot:after {
    content: '';
    position: absolute;
    height: 0%;
    width: 100%;
    padding-bottom: 100%;
    background-color: var(--uib-color);
    border-radius: 50%;
   }
   
   .three-body__dot:nth-child(1) {
    bottom: 5%;
    left: 0;
    transform: rotate(60deg);
    transform-origin: 50% 85%;
   }
   
   .three-body__dot:nth-child(1)::after {
    bottom: 0;
    left: 0;
    animation: wobble1 var(--uib-speed) infinite ease-in-out;
    animation-delay: calc(var(--uib-speed) * -0.3);
   }
   
   .three-body__dot:nth-child(2) {
    bottom: 5%;
    right: 0;
    transform: rotate(-60deg);
    transform-origin: 50% 85%;
   }
   
   .three-body__dot:nth-child(2)::after {
    bottom: 0;
    left: 0;
    animation: wobble1 var(--uib-speed) infinite
       calc(var(--uib-speed) * -0.15) ease-in-out;
   }
   
   .three-body__dot:nth-child(3) {
    bottom: -5%;
    left: 0;
    transform: translateX(116.666%);
   }
   
   .three-body__dot:nth-child(3)::after {
    top: 0;
    left: 0;
    animation: wobble2 var(--uib-speed) infinite ease-in-out;
   }
   
   @keyframes spin78236 {
    0% {
     transform: rotate(0deg);
    }
   
    100% {
     transform: rotate(360deg);
    }
   }
   
   @keyframes wobble1 {
    0%,
     100% {
     transform: translateY(0%) scale(1);
     opacity: 1;
    }
   
    50% {
     transform: translateY(-66%) scale(0.65);
     opacity: 0.8;
    }
   }
   
   @keyframes wobble2 {
    0%,
     100% {
     transform: translateY(0%) scale(1);
     opacity: 1;
    }
   
    50% {
     transform: translateY(66%) scale(0.65);
     opacity: 0.8;
    }
   }

 /* - - - - - - - - media queries - - - - - - - -  */

 @media only screen and (min-width: 370px){
    .day_container{
        width: 300px;
    }
    .shift{
        width: 315px;
    }
    .published_div button{
        width: 320px;
        font-size: 18px;
    }
    .publish_div button{
        width: 320px;
    }
    .btn_ai{
        width: 340px;
    }
    .add_available_worker_div{
        width: 285px;
        font-size: 19px;
    }
    .add_specific_worker_div{
        width: 285px;
        font-size: 19px;
    }
    .add_specific_worker_select{
        width: 295px;
    }
    .name_role_div{
        width: 275px;
    }
    .role_sb_div{
        max-width: 165px;
    }
}
@media only screen and (min-width: 430px){
    .day_container{
        width: 320px;
    }
    .shift{
        width: 335px;
    }
    .published_div button{
        width: 340px;
        font-size: 18px;
    }
    .publish_div button{
        width: 340px;
    }
    .btn_ai{
        width: 360px;
    }
    .add_available_worker_div{
        width: 305px;
        font-size: 20px;
    }
    .add_specific_worker_div{
        width: 305px;
        font-size: 20px;
    }
    .add_specific_worker_select{
        width: 315px;
    }
    .name_role_div{
        width: 295px;
    }
    .role_sb_div{
        max-width: 185px;
    }
}


/* - - - - - - - - - - - - - ai loading animation - - - - - - - - - -  */

.spinnerContainer {
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 240px;
    position: fixed;
  }
  
  .spinner {
    width: 146px;
    height: 146px;
    display: grid;
    border: 4px solid #0000;
    border-radius: 50%;
    border-right-color: var(--main-color);
    animation: tri-spinner 1s infinite linear;
  }
  
  .spinner::before,
  .spinner::after {
    content: "";
    grid-area: 1/1;
    margin: 2px;
    border: inherit;
    border-radius: 50%;
    animation: tri-spinner 2s infinite;
  }
  
  .spinner::after {
    margin: 8px;
    animation-duration: 3s;
  }
  
  @keyframes tri-spinner {
    100% {
      transform: rotate(1turn);
    }
  }
  
  .loader {
    color: #4a4a4a;
    font-family: "Poppins",sans-serif;
    font-weight: 500;
    font-size: 35px;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 40px;
    padding: 20px 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 8px;
  }
  
  .words {
    overflow: hidden;
  }
  
  .word {
    text-align: center;
    font-family: 'Rubik', sans-serif;
    display: block;
    height: 100%;
    padding-left: 6px;
    color: var(--main-color);
    animation: cycle-words 65s ease-in-out;
  }
  
  @keyframes cycle-words {
    5.5556% {
      transform: translateY(-105%);
    }
  
    11.1111% {
      transform: translateY(-100%);
    }
  
    16.6667% {
      transform: translateY(-205%);
    }
  
    22.2222% {
      transform: translateY(-200%);
    }
  
    27.7778% {
      transform: translateY(-305%);
    }
  
    33.3333% {
      transform: translateY(-300%);
    }
  
    38.8889% {
      transform: translateY(-405%);
    }
  
    44.4444% {
      transform: translateY(-400%);
    }
  
    50% {
      transform: translateY(-500%);
    }
  
    55.5556% {
      transform: translateY(-505%);
    }
  
    61.1111% {
      transform: translateY(-605%);
    }
  
    66.6667% {
      transform: translateY(-600%);
    }
  
    72.2222% {
      transform: translateY(-705%);
    }
  
    77.7778% {
      transform: translateY(-700%);
    }
  
    83.3333% {
      transform: translateY(-805%);
    }
  
    88.8889% {
      transform: translateY(-800%);
    }
  
    94.4444% {
      transform: translateY(-900%);
    }
  
    100% {
      transform: translateY(-900%);
    }
  }
