.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 70px;
}

@keyframes slideInAndFade {
    0% {
        transform: translateX(0);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
.day_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    width: 290px;
    margin: 12px;
    border-radius: 15px;
    animation: slideInAndFade 0.5s ease-in-out;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    background-color: #f2f4ffe4;
    margin-bottom: 10px;
}

@keyframes downToUp {
    0% {
        transform: translateY(130px);
        opacity: 1;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
  }
}

.messege{
    position: absolute;
    bottom: 30px;
    z-index: 900;
    position:fixed;
    direction: rtl;
}

.messege p{
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 13px;
    border-radius: 8px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    color: #2B641E;
    cursor: pointer;
    width: 310px;
    padding: 15px;
    animation: downToUp 1s ease-in-out;
    background-color: #EDFBD8;
    border: 1px solid #84D65A;
    box-shadow: 0px 0px 5px -3px #111;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
}
.message_icon{
    font-size: 23px;
    margin-left: 10px;
}

.h2_div{
    align-items: center;
    text-align: right;
    direction: rtl;
    width: 100%;
}

.h2{
    font-size: 22px;
    margin-top: -10px;
    margin-bottom: 2px;
    font-weight: 500;
    color: #287fcce2;
    margin-left: 160px;
}

.shift{
    width: 300px;
    font-size: 1.2em;
    border-radius: 10px;
    margin: 10px;
    margin-bottom: -5px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.no_shifts_message{
    color: rgb(131, 131, 131);
    margin-top: 4px;
    font-size: 1.3em;
}

.no_shifts_messge{
    color: rgb(111, 111, 111);
    margin-top: 4px;
    font-size: 1.3em;
}

.buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.btn {
    margin-top: 15px;
    margin: 5px;
    width: 87px;
    height: 70px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 13px;
    line-height: 1.25rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.25);
    gap: 0.75rem;
    background-color: #34a0ff;
    color: white;
    cursor: pointer;
    transition: 0.3s;
    justify-content: center;
}
.btn:hover {
    background-color: #2068a7;
}

.remove_btn{
    margin: 10px;
    margin-top: 0px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 1.275rem;
    line-height: 1.25rem;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 15px;
    border: none;
    gap: 0.75rem;
    color: rgb(255, 255, 255);
    background-color: #fb6e6ed8;
    cursor: pointer;
    transition: all .6s ease;
    width: 270px;
    justify-content: center;
    margin-left: 15px;
    font-family: 'Rubik', sans-serif;
    margin-bottom: 5px;
}
.remove_btn:hover {
    transform: scale(1.02);
}

.add_btn{
    margin: 10px;
    margin-top: 0px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 1.275rem;
    line-height: 1.25rem;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 15px;
    border: none;
    gap: 0.75rem;
    color: rgb(65, 63, 63);
    background-color: #fff;
    cursor: pointer;
    transition: all .6s ease;
    width: 270px;
    justify-content: center;
    font-family: 'Rubik', sans-serif;
    margin-left: 15px;
    margin-bottom: 5px;
    background-color: #d2e9ff;
    color:#2880cc;
}
.add_btn:hover {
    transform: scale(1.02);
}

.addShift{
    width: 280px;
    font-size: 1.2em;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    background-color: rgb(244, 247, 250);
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.addShift_btn{
    margin: 10px;
    margin-top: -10px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 1.275rem;
    line-height: 1.25rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.25);
    gap: 0.75rem;
    color: rgb(65, 63, 63);
    background-color: #34a0ff;
    color: white;
    cursor: pointer;
    transition: all .6s ease;
    width: 230px;
    justify-content: center;
}
.addShift_btn:hover {
    transform: scale(1.02);
}

.btn_delete{
    background-color: rgba(215, 215, 215, 0.642);
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 5px;
    padding: 9px;
    font-size: 16px;
    margin-left: 10px;
    margin-bottom: 15px;
    transition: 0.3s;
}
.btn_delete:hover{
    cursor: pointer;
    color: white;
    background-color: rgba(255, 0, 0, 0.604);
}

.shift_name{
    margin-bottom: -5px;
}

.shift_data_p{
    margin: 12px;
    font-weight: 400;
    font-size:19px;
    margin-top: 15px;
}

.workers_showList{
    direction: rtl;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
}

/* - - - - - - - - - - - when user see the shifts after they published - - - - - - - -  */

.shift_data_published{
    margin: 12px;
    margin-right: 5px;
    display: flex;
    flex-direction: row;
    direction: rtl;
    width: 300px;
    text-align: right;
}
.description_label{
    margin-right: 25px;
}

.all_data_div{
    margin: 8px;
    margin-right: 10px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.name_and_icon_div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.sb_icon{
    margin-top: 0px;
    margin-right: -2px;
}

.hours_message_div_SB{
    gap: 5px;
    margin-top: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.name{
    text-overflow: ellipsis;
    max-width: 110px;
    white-space: nowrap;
    overflow: hidden; 
}

.hours_message_div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.icon{
    color: rgba(101, 100, 100, 0.903);
    font-size: 21px;
    margin-right: 10px;
}

.btn_chose{
    margin: 15px;
    background-color: rgba(144, 144, 144, 0.684);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 9px;
}

.publish_div{
    margin-top: 90px;
}

.publish_div button{
    margin-top: -10px;
    margin-bottom: 0px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 1.275rem;
    line-height: 1.25rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.25);
    gap: 0.75rem;
    color: rgb(65, 63, 63);
    background-color: #34a0ff;
    color: white;
    cursor: pointer;
    transition: all .6s ease;
    width: 320px;
    justify-content: center;
}
.publish_div button:hover {
    transform: scale(1.02);
}

.published_div{
    margin-top: 90px;
}

.published_div button{
    margin-top: -10px;
    margin-bottom: 0px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 1.275rem;
    line-height: 1.25rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.25);
    gap: 0.75rem;
    color: rgb(46, 181, 29);
    background-color: #ffffffe1;
    border: none;
    transition: all .6s ease;
    width: 320px;
    justify-content: center;
}

.bold_name{
    font-weight: 550;
    text-overflow: ellipsis;
    max-width: 110px;
    white-space: nowrap;
    overflow: hidden; 
}

.icon_message{
    font-size: 23px;
    margin-right: 12px;
    margin-bottom: -2px;
}

.messege_to_manager_div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.input_to_manager{
    margin-top: 90px;
    font-size: 18px;
    direction: rtl;
    width: 300px;
    padding: 8px;
    border-radius: 0.5rem;
    border: 1px solid rgba(80, 80, 80, 0.52);
}
.btn_to_manager{
    font-size: 22px;
    margin-top: 15px;
    margin-bottom: -60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    line-height: 1.25rem;
    font-weight: 700;
    border-radius: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.25);
    gap: 0.75rem;
    color: rgb(255, 255, 255);
    background-color: #34a0ff;
    border: none;
    transition: all .6s ease;
    width: 315px;
    height: 60px;
    justify-content: center;
    text-align: center;
}

.write_message_btn{
    width: 60px;
    height: 60px;
    z-index: 10;
    position: fixed;
    bottom: 15px;
    left: 15px;
    color: white;
    background-color: #34a0ff;
    font-size: 41px;
    transition: 0.3s;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 300%;
    border: none;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    animation: downToUp 1s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

 /* - - - - - - loading animation - - - - - - - - -  */

 .animation_container{
    width: 300px;
    display: flex;
    justify-content: center; 
    align-items: center;
    background-color: #84D65A; 
 }

 .three-body {
    --uib-size: 35px;
    --uib-speed: 0.8s;
    --uib-color: var(--main-color);
    display: inline-block;
    height: var(--uib-size);
    width: var(--uib-size);
    animation: spin78236 calc(var(--uib-speed) * 2.5) infinite linear;
}

.three-body__dot {
    position: absolute;
    height: 100%;
    width: 30%;
}

.three-body__dot:after {
    content: '';
    position: absolute;
    height: 0%;
    width: 100%;
    padding-bottom: 100%;
    background-color: var(--uib-color);
    border-radius: 50%;
}

.three-body__dot:nth-child(1) {
    bottom: 5%;
    left: 0;
    transform: rotate(60deg);
    transform-origin: 50% 85%;
}

.three-body__dot:nth-child(1)::after {
    bottom: 0;
    left: 0;
    animation: wobble1 var(--uib-speed) infinite ease-in-out;
    animation-delay: calc(var(--uib-speed) * -0.3);
}

.three-body__dot:nth-child(2) {
    bottom: 5%;
    right: 0;
    transform: rotate(-60deg);
    transform-origin: 50% 85%;
}

.three-body__dot:nth-child(2)::after {
    bottom: 0;
    left: 0;
    animation: wobble1 var(--uib-speed) infinite calc(var(--uib-speed) * -0.15) ease-in-out;
}

.three-body__dot:nth-child(3) {
    bottom: -5%;
    left: 0;
    transform: translateX(116.666%);
}

.three-body__dot:nth-child(3)::after {
    top: 0;
    left: 0;
    animation: wobble2 var(--uib-speed) infinite ease-in-out;
}

@keyframes spin78236 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes wobble1 {
    0%,
    100% {
        transform: translateY(0%) scale(1);
        opacity: 1;
    }

    50% {
        transform: translateY(-66%) scale(0.65);
        opacity: 0.8;
    }
}

@keyframes wobble2 {
    0%,
    100% {
        transform: translateY(0%) scale(1);
        opacity: 1;
    }

    50% {
        transform: translateY(66%) scale(0.65);
        opacity: 0.8;
    }
}


.swal2_title{
    font-size: 28px;
}

.content{
    font-size: 22px;
}

@media only screen and (min-width: 300px){
    .h2{
        margin-left: 140px;
    }
    .shift_data_published{
        width: 285px;
    }
}

@media only screen and (min-width: 370px){
    .day_container{
        width: 310px;
    }
    .shift{
        width: 320px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .shift_data_published{
        width: 320px;
    }
    .messege p{
        width: 330px;
        font-size: 15px;
    }
    .remove_btn{
        margin-left: 10px;
    }
    .add_btn{
        margin-left: 10px;
    }
    .workers_showList{
        width: 320px;
    }
}
@media only screen and (min-width: 430px){
    .day_container{
        width: 320px;
    }
    .shift{
        width: 340px;
    }
    .messege p{
        width: 360px;
        font-size: 16px;
    }
    .remove_btn{
        margin-left: 10px;
    }
    .add_btn{
        margin-left: 10px;
    }
    .workers_showList{
        width: 330px;
    }
}




/* - - - - - - - - - - - - - - - expend icon animation - - - - - - - - - - - -  */

/*------ Settings ------*/
.icon_container {
    --color: #287fcc6a;
    --size: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-size: var(--size);
    user-select: none;
    fill: var(--color);
    margin-right: 15px;
  }
  
  .icon_container .chevron_down {
    position: absolute;
    animation: keyframes-return .5s;
  }
  
  /* ------ On check event ------ */
  .icon_container input:checked ~ .chevron_down {
    animation: keyframes-rotate .5s;
    transform: rotate(180deg);
  }
  
  /* ------ Hide the default checkbox ------ */
  .icon_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* ------ Animation ------ */
  @keyframes keyframes-rotate {
    0% {
      transform: rotate(0deg);
      opacity: 0;
    }
  
    100% {
      transform: rotate(-180deg);
    }
  }
  
  @keyframes keyframes-return {
    0% {
      transform: rotate(-180deg);
      opacity: 0;
    }
  
    100% {
      transform: rotate(0deg);
    }
  }