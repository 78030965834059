@keyframes slideInAndFade {
    0% {
        transform: translateX(0);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 290px;
    height: 440px;
    padding: 20px;
    margin-top: 90px;
    animation: slideInAndFade 0.5s ease-in-out;
    position: relative;
    overflow: hidden;
    background-color: #dde0ef73;
    border-radius: 20px;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.h2{
    margin-top: 0px;
    font-size: 1.45em;
    color: #34a0ff;
    font-weight: 500;
}

.input{
    text-align: right;
    font-size: 20px;
    padding: 10px;
    border-radius: 12px;
    border: none;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 80%;
    border: none;
    direction: rtl;
    font-family: 'Rubik', sans-serif;
    border: 1px solid rgba(206, 206, 206, 0.452);
}
.input::placeholder {
    font-size: 15px;
}

.emptyInput {
    border: 3px solid rgb(247, 80, 71);
}

.btn {
    margin-top: 20px;
    display: flex;
    padding: 0.8rem 2.4rem;
    font-size: 1.275rem;
    line-height: 1.25rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
    border-radius: 12px;
    border:none;
    gap: 0.75rem;
    color: white;
    background-color: #63b6ff;
    cursor: pointer;
    transition: all .6s ease;
    width: 89%;
    justify-content: center;
    font-family: 'Rubik', sans-serif;
    margin-bottom: 205px;
    z-index: 1;
}
.btn:hover {
transform: scale(1.02);
}

.password_time_svg {
    z-index: 0;
    position: absolute;
    bottom: -65px;
    opacity: 0.9;
    width: 300px;
    height: 320px;
    animation: downToUp 1.5s ease-in-out;
}

.alert{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: aquamarine;
}

@keyframes downToUp {
    0% {
        transform: translateY(50px);
    }
    100% {
        transform: translateY(0);
  }
}



@media only screen and (min-width: 380px){
    .container{
        width: 310px;
    }
    .btn{
        width: 270px;
    }
}
@media only screen and (min-width: 430px){
    .container{
        width: 330px;
    }
    .btn{
        width: 290px;
    }
}