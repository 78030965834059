:root{
    --main-color: #34a0ff;
}

.container{
    direction: ltr;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

@keyframes downToUp {
    0% {
        transform: translateY(130px);
        opacity: 1;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
  }
}

.edit_week_btn {
    width: 60px;
    height: 60px;
    z-index: 10;
    position: fixed;
    bottom: 15px;
    left: 15px;
    background-color: #34a0ff;
    color: white;
    border: none;
    transition: 0.3s;
    border-radius: 300%;
    font-size: 25px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    animation: downToUp 1s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.edit_week_btn:hover{
    cursor: pointer;
}


 /* - - - - - - - - day componenet - - - - - - - -  */

.day_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 290px;
    margin: 20px;
    padding-bottom: 10px;
    background-color: #f2f4ffe4;
    border-radius: 15px;
    animation: slideInAndFade 0.5s ease-in-out;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.div_h2_day{
    display: flex;
    flex-direction: row;
    text-align: right;
    width: 100%;
    direction: rtl;
}

.h2{
    font-size: 22px;
    margin-top: -10px;
    margin-bottom: 0px;
    font-weight: 500;
    color: #287fcce2;
}

.no_shifts_messge{
    color: rgb(111, 111, 111);
    margin-top: 4px;
    font-size: 1.3em;
}



 /* - - - - - - - - shift componenet - - - - - - - -  */

 .shift{
    width: 300px;
    font-size: 1.2em;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.shift_description{
    font-size: 19px;
    margin-bottom: 5px;
    display: flex;
    direction: rtl;
    align-items: center;
}
.description_label{

}

.under_icon{
    margin-right: 10px;
    font-size: 23px;
}

 /* - - - - - - - - worker componenet - - - - - - - -  */

 .all_data_div{
    margin: 8px;
    margin-right: 10px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.all_data_div_clear{
    margin: 8px;
    margin-right: 10px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    gap: 10px;
}

.name_role_div{
    display: flex;
    flex-direction: row;
}

.name{
    direction: rtl;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 110px;
}

.role_div{
    text-align: right;
    color: rgb(125, 125, 125);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 145px;
}

.alert_div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 7px;
    justify-content: left;
    width: 45px;
    margin-bottom: -6px;
}
.icon{
    color: rgba(119, 119, 119, 0.903);
    font-size: 21px;
    cursor: pointer;
}

.workers_showList{
    direction: rtl;
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* - - - - - - - - media quries  - - - - - - - - - - - - - -  */

@media only screen and (min-width: 300px){
    .shift_description {
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 310px;
        max-width: 310px;
        white-space: nowrap;
    }
    .description_label {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 260px;
        max-width: 260px;
        margin-right: 20px;
    }
}

@media only screen and (min-width: 370px){
    .day_container{
        width: 310px;
    }
    .shift{
        width: 320px;
    }
    .shift_description {
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 330px;
        max-width: 330px;
        white-space: nowrap;
    }
    .description_label {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 285px;
        max-width: 285px;
        margin-right: 20px;
    }
    
}

@media only screen and (min-width: 430px){
    .day_container{
        width: 330px;
    }
    .shift{
        width: 340px;
    }
    .shift_description {
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 350px;
        max-width: 350px;
        white-space: nowrap;
    }
    .description_label {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 310px;
        max-width: 310px;
        margin-right: 20px;
    }
}

/* - - - - - - - - - - - - - - - expend icon animation - - - - - - - - - - - -  */

.icon_container {
    --color: #287fcc6a;
    --size: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-size: var(--size);
    user-select: none;
    fill: var(--color);
    margin-right: 20px;
  }
  
  .icon_container .chevron_down {
    position: absolute;
    animation: keyframes-return .5s;
  }
  
  /* ------ On check event ------ */
  .icon_container input:checked ~ .chevron_down {
    animation: keyframes-rotate .5s;
    transform: rotate(180deg);
  }
  
  /* ------ Hide the default checkbox ------ */
  .icon_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* ------ Animation ------ */
  @keyframes keyframes-rotate {
    0% {
      transform: rotate(0deg);
      opacity: 0;
    }
  
    100% {
      transform: rotate(-180deg);
    }
  }
  
  @keyframes keyframes-return {
    0% {
      transform: rotate(-180deg);
      opacity: 0;
    }
  
    100% {
      transform: rotate(0deg);
    }
  }