.container{
    background-color: #648ef8;
    height: 100dvh;
    width: 100dvw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Rubik', sans-serif;
    z-index: 999;
}

.backIcon {
    border: 1px solid white;
    border-radius: 150px;
    padding: 9px;
    color: white;
    position: absolute;
    left: 18px;
    top: 18px;
    font-size: 28px;
    transition: padding 0.3s, font-size 0.3s;
}

.backIcon:hover {
    padding: 8px;
    font-size: 26px;
}

.upper_text {
    position: absolute;
    top: -100px;
    right: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: right;
    text-align: right;
    animation: slideFromTop 1s ease forwards;
}

@keyframes slideFromTop {
    from {
        top: -200px; /* Starting position off the top of the page */
    }
    to {
        top: 10px; /* Final position */
    }
}


.title_div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; /* Changed to align items to the right */
    align-items: center; /* Adjusted alignment */
    gap: 10px;
    margin-top: 50px;
}

.lock_icon {
    font-size: 50px;
    margin-bottom: 4px;
    transform: rotate(-20deg);
}

.span_title {
    color: white;
    font-weight: 600;
    font-size: 40px;
    font-family: 'Rubik', sans-serif;
    text-align: right;
}

.under_title {
    color: white;
    font-weight: 400;
    font-size: 19px;
    font-family: 'Rubik', sans-serif;
    margin-top: 5px;
    text-align: right; /* Ensure text is aligned to the right */
}


.middle_div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 40%;
}
.input_div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.input {
    width: 40px;
    height: 55px;
    border-radius: 18px;
    border: none;
    background-color: rgb(255, 255, 255);
    font-family: 'Rubik', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 34px;
    text-align: center;
    outline: none;
    color: rgb(78, 78, 78);
    font-weight: 500;
    color: #648ef8;
}
.input:hover {
}
.input:focus {
    outline: none;
}


.another_code{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 35px;
}
.btn{
    background-color: white;
    color: #648ef8;
    width: 300px;
    border: none;
    font-size: 30px;
    font-weight: 600;
    font-family: 'Rubik', sans-serif;
    padding: 15px;
    border-radius: 35px;
    margin-bottom: 15px;
}
.another_code label{
    color: white;
    direction: rtl;
    font-size: 18px;
    margin-bottom: 4px;
    font-family: 'Rubik', sans-serif;
}
.btn_again{
    color: white;
    direction: rtl;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    font-family: 'Rubik', sans-serif;
}

@media only screen and (min-width: 480px) {
    .container{
        height: 700px;
        width: 490px;
        border-radius: 30px;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        position: relative;
        margin-top: 70px;
    }
    .input{
        width: 60px;
        height: 60px;
    }
    .upper_text{
        position: absolute;
        right: 35px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: right;
        text-align: right;
    }
  }