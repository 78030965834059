@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Bebas+Neue&family=Creepster&family=Genos:wght@100&family=JetBrains+Mono:wght@100&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&family=Oswald:wght@500&family=Rubik:ital,wght@0,400;0,500;0,600;0,800;1,400&family=Space+Grotesk:wght@300&display=swap');

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-family: 'Rubik', sans-serif;
  background-color: white !important;
}