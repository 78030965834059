.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    margin-top: 87px;
    overflow: hidden;
}

.currentWeek_container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.link{
  text-decoration: none;
}

.week_title_container{
  width: 340px;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: end;
  margin-left: 5px;
}

.btnUp{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  height: 110px;
  background-color:#EFF5FF;
  border: none;
  width: 340px;
  transition: 0.3s;
  margin-bottom: 7px;
  border-radius: 24px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
  overflow: hidden;
  z-index: 10;
  border-top-right-radius: 0px;
}
.btnUp:hover{
  cursor: pointer;
}

.btn{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  height: 110px;
  background-color:#EFF5FF;
  border: none;
  width: 340px;
  transition: 0.3s;
  margin-bottom: 5px;
  border-radius: 24px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
  overflow: hidden;
}
.btn:hover{
  cursor: pointer;
}

.text_div{
  font-size: 18px;
  color: #565656;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.finished_icon{
  font-size: 22px;
  animation: slideInAndFade 0.5s ease-in-out;
}

.week_text_1{
  font-size: 16px;
  font-weight: 500;
  background-color: #5790FF;
  color: white;
  padding: 8px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  width: 134px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.week_text_1 label{
  z-index: 2;
}

.icon1{
  animation: textScale 0.4s forwards;
}
.icon2{
  margin-left: 10px;
  animation: textScale 0.6s forwards;
}
.icon3{
  margin-left: 10px;
  animation: textScale 0.8s forwards;
}
.icon4{
  animation: textScale 1s forwards;
}

/* - - - - - - - - - - - - - - - - - - -  buttons create - - - - - - - - - -  */

.week_text_2{
  font-size: 16px;
  font-weight: 500;
  background-color: #5790FF;
  color: white;
  padding: 8px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  width: 134px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.week_text_2 label{
  z-index: 2;
}

.btn3{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  height: 110px;
  background-color:#EFF5FF;
  border: none;
  width: 340px;
  transition: 0.3s;
  margin-bottom: 7px;
  border-radius: 24px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
  overflow: hidden;
  z-index: 10;
  border-top-right-radius: 0px;
}
.btn3:hover{
  cursor: pointer;
}

.btn4{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  height: 110px;
  background-color:#EFF5FF;
  border: none;
  width: 340px;
  transition: 0.3s;
  margin-bottom: 5px;
  border-radius: 24px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
  overflow: hidden;
}
.btn4:hover{
  cursor: pointer;
}



/* - - - - - - - - - animations - - - - - - - - - -  */

@keyframes slideInAndFade {
  0% {
      transform: translateX(0);
      opacity: 0;
  }
  100% {
      transform: translateX(0);
      opacity: 1;
  }
}

@keyframes slideDown {
  0% {
    transform: translateX(80px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes textScale {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes rotateImage {
  0% {
      transform: rotate(50deg);
  }
  100% {
    transform: rotate(-50deg);
}
  100% {
      transform: rotate(0deg);
  }
}


/* - - - - - - - - - - media queries - - - - - - -  - - - -  */

@media only screen and (min-height: 730px) {
  .btn{
      height:120px;
  }
  .btnUp{
    height:120px;
  }
  .btn3{
    height:120px;
}
.btn4{
  height:120px;
}
}
@media only screen and (max-height: 655px) {
  .btn{
      height:100px;
  }
  .btnUp{
    height:100px;
  }
  .btn3{
    height:100px;
}
.btn4{
  height:100px;
}
}

@media only screen and (min-width: 0px) and (max-width: 329.9px) {
  .btn{
    width: 270px;
  }
  .btnUp{
    width: 270px;
  }
  .btn3{
    width: 270px;
  }
  .btn4{
    width: 270px;
  }
  .week_title_container{
    width: 265px;
  }
}
@media only screen and (min-width: 330px) and (max-width: 369px) {
  .btn{
    width: 315px;
    font-size: 18px;
  }
  .btnUp{
    width: 315px;
    font-size: 18px;
  }
  .btn3{
    width: 315px;
    font-size: 18px;
  }
  .btn4{
    width: 315px;
    font-size: 18px;
  }
  .week_title_container{
    width: 310px;
  }
}
@media only screen and (min-width: 370px) and (max-width: 399.9px) {
  .btn{
    width: 340px;
  }
  .btnUp{
    width: 340px;
  }
  .btn3{
    width: 340px;
  }
  .btn4{
    width: 340px;
  }
  .week_title_container{
    width: 335px;
  }
}
@media only screen and (min-width: 400px) and (max-width: 510px) {
  .btn{
    width: 360px;
    font-size: 18px;
  }
  .btnUp{
    width: 360px;
    font-size: 18px;
  }
  .btn3{
    width: 360px;
    font-size: 18px;
  }
  .btn4{
    width: 360px;
    font-size: 18px;
  }
  .week_title_container{
    width: 355px;
  }
}
@media only screen and (min-width: 511px) {
  .btn{
    width: 360px;
    font-size: 18px;
  }
  .btnUp{
    width: 360px;
    font-size: 18px;
  }
  .btn3{
    width: 360px;
    font-size: 18px;
  }
  .btn4{
    width: 360px;
    font-size: 18px;
  }
  .week_title_container{
    width: 355px;
  }
}