.all_roles_div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 290px;
    padding: 12px;
    margin-top: 90px;
    background-color: #dde0ef73;
    border-radius: 15px;
    animation: slideInAndFade 0.5s ease-in-out;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

@keyframes slideInAndFade {
    0% {
        transform: translateX(0);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.label{
    font-size: 18px;
    margin: 10px;
    margin-right: 10px;
}

.roles{
    width: 280px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 10px;
    margin: 10px;
    margin-bottom: 3px;
    padding: 3px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(255, 255, 255);
}

.delete_btn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(205, 205, 205, 0);
    color: rgba(227, 86, 86, 0.989);
    border: none;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    font-size: 25px;
    margin-left: 5px;
    transition: 0.3s;
}
.delete_btn:hover{
    cursor: pointer;
}

@keyframes downToUp {
    0% {
        transform: translateY(130px);
        opacity: 1;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
  }
}
.addUser_btn {
    width: 60px;
    height: 60px;
    z-index: 10;
    position: fixed;
    bottom: 15px;
    left: 15px;
    background-color: #34a0ff;
    color: white;
    border: none;
    font-size: 41px;
    transition: 0.3s;
    border-radius: 300%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    animation: downToUp 1s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.addUser_btn:hover{
    cursor: pointer;
}

.noRoles_div{
    font-size: 20px;
    font-weight: 500;
    color: rgb(112, 112, 112);
}

.three-body {
    --uib-size: 35px;
    --uib-speed: 0.8s;
    --uib-color: #34a0ff;
    position: relative;
    display: inline-block;
    height: var(--uib-size);
    width: var(--uib-size);
    animation: spin78236 calc(var(--uib-speed) * 2.5) infinite linear;
   }
   
   .three-body__dot {
    position: absolute;
    height: 100%;
    width: 30%;
   }
   
   .three-body__dot:after {
    content: '';
    position: absolute;
    height: 0%;
    width: 100%;
    padding-bottom: 100%;
    background-color: var(--uib-color);
    border-radius: 50%;
   }
   
   .three-body__dot:nth-child(1) {
    bottom: 5%;
    left: 0;
    transform: rotate(60deg);
    transform-origin: 50% 85%;
   }
   
   .three-body__dot:nth-child(1)::after {
    bottom: 0;
    left: 0;
    animation: wobble1 var(--uib-speed) infinite ease-in-out;
    animation-delay: calc(var(--uib-speed) * -0.3);
   }
   
   .three-body__dot:nth-child(2) {
    bottom: 5%;
    right: 0;
    transform: rotate(-60deg);
    transform-origin: 50% 85%;
   }
   
   .three-body__dot:nth-child(2)::after {
    bottom: 0;
    left: 0;
    animation: wobble1 var(--uib-speed) infinite
       calc(var(--uib-speed) * -0.15) ease-in-out;
   }
   
   .three-body__dot:nth-child(3) {
    bottom: -5%;
    left: 0;
    transform: translateX(116.666%);
   }
   
   .three-body__dot:nth-child(3)::after {
    top: 0;
    left: 0;
    animation: wobble2 var(--uib-speed) infinite ease-in-out;
   }
   
   @keyframes spin78236 {
    0% {
     transform: rotate(0deg);
    }
   
    100% {
     transform: rotate(360deg);
    }
   }
   
   @keyframes wobble1 {
    0%,
     100% {
     transform: translateY(0%) scale(1);
     opacity: 1;
    }
   
    50% {
     transform: translateY(-66%) scale(0.65);
     opacity: 0.8;
    }
   }
   
   @keyframes wobble2 {
    0%,
     100% {
     transform: translateY(0%) scale(1);
     opacity: 1;
    }
   
    50% {
     transform: translateY(66%) scale(0.65);
     opacity: 0.8;
    }
   }




@media only screen and (min-width: 380px){
    .roles{
        width: 300px;
    }
    .all_roles_div{
        width: 310px;
    }
}
@media only screen and (min-width: 430px){
    .roles{
        width: 320px;
    }
    .all_roles_div{
        width: 330px;
    }
}